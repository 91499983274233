import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetWardSectionsQuery } from 'redux/rtk/units/unitsApi'
import { MedicalUnitType } from 'redux/rtk/wardView/types'
import { useGetWardViewDataQuery } from 'redux/rtk/wardView/wardViewApi'

// Custom hook to handle fetching ward data
export const useWardViewData = () => {
    const { department } = useParams<{ department: string }>()
    const {
        data: wardSectionsData,
        isLoading,
        isError,
    } = useGetWardSectionsQuery()

    // Determine ward selection safely, defaulting to an empty string if undefined
    const wardSelection = useMemo(() => {
        return department || wardSectionsData?.sections?.[0] || ''
    }, [department, wardSectionsData])

    // Call the query only if wardSelection is not an empty string
    const queryResponse = useGetWardViewDataQuery(
        { wardSelection, page: 1, size: 1000 },
        {
            pollingInterval: 300000, // Poll every 5 minutes
            skip: !wardSelection, // Skip the query if wardSelection is empty
            refetchOnMountOrArgChange: true,
        }
    )

    return {
        ...queryResponse,
        isLoadingSections: isLoading,
        isSectionsError: isError,
        wardSelection,
    }
}
