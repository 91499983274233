import { createSlice } from '@reduxjs/toolkit'
import { PatientDetails } from './types'

const emptyPatientDetails: PatientDetails = {
    patientUuid: '',
    mrn: '',
    firstName: '',
    lastName: '',
    visitDiagnosis: '',
    visitLengthOfStaySinceAdmissionHours: 0,
    visitFollowingDoctorName: '',
    visitIsolationStatus: null,
    locationServiceName: null,
    locationUnitName: '',
    locationRoomName: '',
    locationBedName: '',
    patientGender: '',
    patientAge: 0,
    communityCareInstitution: null,
    communityIntervenantPivotName: null,
    communityProgram: null,
    communityLastInterventionDatetime: null,
    communityInterventionProgram: null,
    patientVisits: [],
    patientSurgeries: [],
    patientResults: [],
    lastModifiedDate: '',
    familyMdName: null,
    patientCareFactors: null,
    visitExpectedPlannedDischargeHours: null,
    actualTransferStatus: null,
    actualTransferStatusStartDateTime: null,
    patientProvenance: null,
    patientTerritory: null,
}

type PatientViewState = {
    patientDetails: PatientDetails
}

const initialState: PatientViewState = {
    patientDetails: emptyPatientDetails,
}

const patientSlice = createSlice({
    name: 'patientView',
    initialState,
    reducers: {
        setPatientDetails: (state, action) => {
            state.patientDetails = action.payload
        },
        clearPatientDetails: (state) => {
            state.patientDetails = emptyPatientDetails
        },
    },
})

export const { setPatientDetails, clearPatientDetails } = patientSlice.actions
export default patientSlice.reducer
