import { LabsDataResponse, WardViewData } from 'redux/rtk/wardView/types'

export const WARD_PATIENTS_MOCK: WardViewData = {
    wards: [
        {
            id: 2024099974,
            patientId: '06a78bac-1dcf-4c02-9a37-4168b29db9fb',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 514,
            visitExpectedPlannedDischargedHours: 242,
            visitFollowingDoctorName: 'MAKKI LEEN',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0216',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '99256',
            patientFirstName: 'EMMA',
            patientName: 'ROY',
            patientGender: 'FEMALE',
            patientAge: 76,
            patientProvenance: 'Home',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [
                'has_clsc_mental_health_record',
                'is_highly_vulnerable',
                'has_mental_health_diagnosis',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: 'ac716a5b-b7ac-4c77-8084-ca7f97dc9e3b',
                    uniqueId: '582d64620254978723b1185774f197de',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-17T00:00:00',
                },
                {
                    patientId: 'ac716a5b-b7ac-4c77-8084-ca7f97dc9e3b',
                    uniqueId: '6b9e4c45fe7f73d157fe25345331e60f',
                    imageDescription: 'SEARCH FOR INFECTION SITE- AXIAL PLAN',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-17T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: true,
            isolationStatus: 'CVR',
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: true,
            readLabsCount: 0,
            allLabsAreRead: true,
            predictedLengthOfStayCategory: null,
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '1-3 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: [
                'Delirium symptoms',
                'Confinement in an institution judgment',
                'Other legal issues',
            ],
        },
        {
            id: 2024101842,
            patientId: '37204b10-c5af-4dee-ada6-db82ef5dc5d9',
            visitDiagnosis: 'reconstruction acl (anterior crurate ligament)',
            visitLengthOfStaySinceAdmissionHours: 19,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0225',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '368364',
            patientFirstName: 'LUCAS',
            patientName: 'TREMBLAY',
            patientGender: 'MALE',
            patientAge: 78,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: false,
            isolationStatus: 'CVICU ',
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: [],
        },
        {
            id: 2024099163,
            patientId: '7efea461-f55b-4d80-ad58-4b9f47d3d016',
            visitDiagnosis: 'non stemi',
            visitLengthOfStaySinceAdmissionHours: 706,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLOIS YVES',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0231',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1396750',
            patientFirstName: 'OLIVER',
            patientName: 'FORTIN',
            patientGender: 'MALE',
            patientAge: 75,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: true,
            readLabsCount: 2,
            allLabsAreRead: true,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '10 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024099556,
            patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 634,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0255',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '988131',
            patientFirstName: 'LIAM',
            patientName: 'BERNIER',
            patientGender: 'MALE',
            patientAge: 67,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: true,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '2-4 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101929,
            patientId: '1fc5d078-6c14-4b75-9d3c-045249d968d1',
            visitDiagnosis: 'to be specified',
            visitLengthOfStaySinceAdmissionHours: 1,
            visitExpectedPlannedDischargedHours: 137,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0274',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1655142',
            patientFirstName: 'BENJAMIN',
            patientName: 'LAVOIE',
            patientGender: 'MALE',
            patientAge: 62,
            patientProvenance: null,
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 3,
            completedImages: [
                {
                    patientId: 'ac716a5b-b7ac-4c77-8084-ca7f97dc9e3b',
                    uniqueId: '582d64620254978723b1185774f197de',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-17T00:00:00',
                },
                {
                    patientId: 'ac716a5b-b7ac-4c77-8084-ca7f97dc9e3b',
                    uniqueId: '6b9e4c45fe7f73d157fe25345331e60f',
                    imageDescription: 'SEARCH FOR INFECTION SITE- AXIAL PLAN',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-17T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: true,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: true,
            readLabsCount: 10,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101072,
            patientId: '1529aab6-c884-4b66-adbb-e42282e8c2be',
            visitDiagnosis: 'ami - myocardial infarct acute',
            visitLengthOfStaySinceAdmissionHours: 225,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0277',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
                {
                    name: 'medically active',
                    rank: 5,
                },
            ],
            patientRecord: '1809423',
            patientFirstName: 'ETHAN',
            patientName: 'DION-MARCK',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 4,
            completedImages: [
                {
                    patientId: '1529aab6-c884-4b66-adbb-e42282e8c2be',
                    uniqueId: 2024028042,
                    imageDescription:
                        'ANGIOLPASTY - PRIMARY - URGENT - 1 - 2 SEGMENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: 'ECMO ',
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101856,
            patientId: '2da9280c-82f2-4384-a754-2b87445bc2fa',
            visitDiagnosis: 'av block, complete',
            visitLengthOfStaySinceAdmissionHours: 15,
            visitExpectedPlannedDischargedHours: 113,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0264',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1638034',
            patientFirstName: 'JACOB',
            patientName: 'DUBOIS',
            patientGender: 'MALE',
            patientAge: 87,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 2,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '1-3 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101539,
            patientId: 'c0a7810a-68aa-46cf-944a-9cb9c3b1d757',
            visitDiagnosis: 'stemi',
            visitLengthOfStaySinceAdmissionHours: 77,
            visitExpectedPlannedDischargedHours: 41,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0221',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '779825',
            patientFirstName: 'EMMA',
            patientName: 'ROY',
            patientGender: 'FEMALE',
            patientAge: 78,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more', 'has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 5,
            completedLabsCount: 6,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: false,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '6-10 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024094903,
            patientId: 'a9318747-c048-479d-98a6-08b87ad75263',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 1905,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'FADOUS MARIA',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0215',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1724094',
            patientFirstName: 'NATHAN',
            patientName: 'POULIN',
            patientGender: 'MALE',
            patientAge: 76,
            patientProvenance: null,
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_highly_vulnerable', 'is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 1,
            completedImages: [
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: 'b7c4dd23753e82066e51b4007a94b0db',
                    imageDescription: 'TOMOGRAPHIE.SUPP.-PRONATION OU REPRISE',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: '01c4a7fac4f4cbad75e4a07337861b21',
                    imageDescription:
                        'Acq. REPOS, REDISTRIBUTION, VIABILITE et/ou DOULEU',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: '674ba3ccf1bad849c951328e77af9b4f',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: 'feca89efe71bf7fd8dad418435f8de19',
                    imageDescription:
                        'ACQ. SOUS PHARMACEUTIQUE avec technologue',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: 'ebd6fa809ebc236aa0b33f10707123e6',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: 'dd265f13d69766305c32d6d70c9312e1',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-19T00:00:00',
                },
                {
                    patientId: 'c1316f7f-d324-4bfc-98ff-ff9c2dec313d',
                    uniqueId: '8374bbd9a7f772151993f551998c5506',
                    imageDescription: 'DOPPLER VEINEUX MEMBRE SUPÉRIEUR',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
            ],
            completedImagesCount: 7,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: false,
            isolationStatus: 'CVP',
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: true,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024100234,
            patientId: '745622be-f4a2-4767-b084-8b0fba0d495d',
            visitDiagnosis: 'dm type 1',
            visitLengthOfStaySinceAdmissionHours: 448,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0236',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '161459',
            patientFirstName: 'AVA',
            patientName: 'GAGNON',
            patientGender: 'FEMALE',
            patientAge: 50,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 5,
            completedImages: [
                {
                    patientId: '745622be-f4a2-4767-b084-8b0fba0d495d',
                    uniqueId: 2024131791,
                    imageDescription: 'ABDOMEN 1 FILM',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: 'ED',
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101777,
            patientId: '7cabc455-dedb-464b-97b7-a00382b5bd2d',
            visitDiagnosis: 'pericarditis, acute',
            visitLengthOfStaySinceAdmissionHours: 30,
            visitExpectedPlannedDischargedHours: 137,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0250',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1810219',
            patientFirstName: 'SOPHIA',
            patientName: 'LAPOINTE',
            patientGender: 'FEMALE',
            patientAge: 58,
            patientProvenance: 'Hospital',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 3,
            completedImages: [
                {
                    patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
                    uniqueId: '5179a8fb51d4f984152536dfcdde28ca',
                    imageDescription:
                        'ARTERIAL SYSTEM CLOSURE INSERT- ANGIOSEAL/PERCLOSE',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
                    uniqueId: '905a8898ca508f87f33a5180c7b86691',
                    imageDescription:
                        'CORONARY ANGIOPLASTY + CORONOGRAPHY 1- 2 SEGMENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
                    uniqueId: 'da5d29c06eff5a377d4f003906a1caf1',
                    imageDescription:
                        'ULTRASOUND - SURFACE-  DURING HEART CATH',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '6f2f29e2-aaf0-417d-8c84-9476ffdb183e',
                    uniqueId: 'e137dce7fcb4cdd5a41d702565c7d5e1',
                    imageDescription: 'ANGIOGRAPHY NON CORONARY',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
            ],
            completedImagesCount: 4,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101508,
            patientId: '3ce0a452-6b89-47a0-83d9-e769705a6281',
            visitDiagnosis: 'ht - hypertension, benign',
            visitLengthOfStaySinceAdmissionHours: 88,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0257',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1199444',
            patientFirstName: 'RAPHAËL',
            patientName: 'CÔTÉ',
            patientGender: 'MALE',
            patientAge: 66,
            patientProvenance: 'Home',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024096975,
            patientId: 'df33223e-3f60-4d7c-bef5-44c57f239aab',
            visitDiagnosis: 'cabg x 2 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 1327,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'LANGLOIS YVES',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0244',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1802181',
            patientFirstName: 'WILLIAM',
            patientName: 'ST-PIERRE',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [
                'is_highly_vulnerable',
                'is_highly_vulnerable',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 1,
            completedImages: [
                {
                    patientId: 'af63c4a3-ffc9-4900-9758-7e3ba33c5427',
                    uniqueId: '0ef47a8745dceee7ab2d81836d145aab',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-14T00:00:00',
                },
                {
                    patientId: 'af63c4a3-ffc9-4900-9758-7e3ba33c5427',
                    uniqueId: '8fcda307beed916b922ac3a3096e590c',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'af63c4a3-ffc9-4900-9758-7e3ba33c5427',
                    uniqueId: '8f5d58dba9cb5d4540e1c05378755e06',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: 'af63c4a3-ffc9-4900-9758-7e3ba33c5427',
                    uniqueId: 'd5051d57947ec0ecb2a86ec3069987a4',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: 'af63c4a3-ffc9-4900-9758-7e3ba33c5427',
                    uniqueId: '6b5345e4741acf6b5c54a5b54210fdeb',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
            ],
            completedImagesCount: 5,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101768,
            patientId: 'c9422620-b701-4a35-95e3-f84daf5f2fe0',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 34,
            visitExpectedPlannedDischargedHours: 161,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0275',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '288773',
            patientFirstName: 'THOMAS',
            patientName: 'BÉLANGER',
            patientGender: 'MALE',
            patientAge: 84,
            patientProvenance: 'Clinic',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101766,
            patientId: '3a9709ae-7f8a-4018-85d8-a4a938274050',
            visitDiagnosis: 'ua - unstable angina',
            visitLengthOfStaySinceAdmissionHours: 34,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0212',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1207299',
            patientFirstName: 'ÉMILIE',
            patientName: 'GIRARD',
            patientGender: 'FEMALE',
            patientAge: 53,
            patientProvenance: 'Clinic',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024097688,
            patientId: '74807110-34af-4b65-bbbf-da5ee9d0c212',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 1099,
            visitExpectedPlannedDischargedHours: 482,
            visitFollowingDoctorName: 'LANGLEBEN DAVID',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0254',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '439292',
            patientFirstName: 'CHLOÉ',
            patientName: 'PERRON',
            patientGender: 'FEMALE',
            patientAge: 68,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'is_highly_vulnerable',
                'has_ccc_mental_health_record',
                'has_vulnerability_signals',
                'has_home_support',
                'is_highly_vulnerable',
                'has_ccc_mental_health_record',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101745,
            patientId: '540c1983-77c5-4094-abe4-84ff2359a836',
            visitDiagnosis: 'cad - coronary artery disease',
            visitLengthOfStaySinceAdmissionHours: 39,
            visitExpectedPlannedDischargedHours: 65,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0210',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
                {
                    name: 'medically active',
                    rank: 6,
                },
            ],
            patientRecord: '1810331',
            patientFirstName: 'OLIVER',
            patientName: 'GIRARD',
            patientGender: 'MALE',
            patientAge: 71,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 1,
            completedImages: [
                {
                    patientId: 'f318d6d5-ab5c-42b5-89a1-9c9d901fb030',
                    uniqueId: 'a22eeb7c97e91ab17d7f985b74338485',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101273,
            patientId: 'd52ba726-b5d4-4459-b3f3-f618781ca2dd',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 178,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0240',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1779280',
            patientFirstName: 'KEVIN',
            patientName: 'JACOB',
            patientGender: 'MALE',
            patientAge: 68,
            patientProvenance: 'Home',
            patientTerritory: 'Montérégie Centre',
            patientTerritoryAcronym: 'MRC',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101391,
            patientId: '606740b7-d6a1-4b7b-a13a-c52ea6e3593e',
            visitDiagnosis: 'necrotizing fasciitis',
            visitLengthOfStaySinceAdmissionHours: 147,
            visitExpectedPlannedDischargedHours: 209,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CI',
            locationRoomName: 'K0276',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809839',
            patientFirstName: 'MIA',
            patientName: 'ST-PIERRE',
            patientGender: 'FEMALE',
            patientAge: 29,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: 'Abitibi Témiscamingue',
            patientTerritoryAcronym: 'AB',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 11,
            completedLabsCount: 6,
            completedImages: [
                {
                    patientId: '74b3d519-6f13-4528-99d8-f5e45bc800d9',
                    uniqueId: '8f9da1d08ba9a56819781f215ecb80f4',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: '74b3d519-6f13-4528-99d8-f5e45bc800d9',
                    uniqueId: '088cbb8a69b2da2bfcfaa2f7f414c6cc',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101669,
            patientId: '47d0ca61-6926-4411-b7c6-240bd240a0ad',
            visitDiagnosis: 'ua - unstable angina',
            visitLengthOfStaySinceAdmissionHours: 56,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'GALATAS CHRISTOS',
            wardSection: 'Cardiology',
            locationUnitName: 'K2CU',
            locationRoomName: 'K0224',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1359474',
            patientFirstName: 'WILLIAM',
            patientName: 'TREMBLAY',
            patientGender: 'MALE',
            patientAge: 91,
            patientProvenance: 'Hospital',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T10:47:01.793954',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: null,
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102458,
            patientId: '0fded5e6-5170-4480-b2b2-cd1c09b6e1d7',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 181,
            visitExpectedPlannedDischargedHours: 149,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1009',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1799506',
            patientFirstName: 'MUHAMMAD',
            patientName: 'SHABBIR',
            patientGender: 'MALE',
            patientAge: 68,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 4,
            completedImages: [
                {
                    patientId: '0fded5e6-5170-4480-b2b2-cd1c09b6e1d7',
                    uniqueId: '29aff56d8b1d1c5a76f93b121d8a5abf',
                    imageDescription: 'MRI HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: '0fded5e6-5170-4480-b2b2-cd1c09b6e1d7',
                    uniqueId: '4e9f3742720c6b14a5f9f62e43c81fcf',
                    imageDescription: 'ABDOMEN 2 FILMS OR MORE',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
                {
                    patientId: '0fded5e6-5170-4480-b2b2-cd1c09b6e1d7',
                    uniqueId: 'a05808a95a917566f3a4b19fdb44a9ec',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
            ],
            completedImagesCount: 3,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103174,
            patientId: '80056160-d62e-43b1-9545-fd67f2fc330a',
            visitDiagnosis: 'abnormal involuntary mouvements',
            visitLengthOfStaySinceAdmissionHours: 7,
            visitExpectedPlannedDischargedHours: 188,
            visitFollowingDoctorName: 'BELKHOUS NACERA',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1006',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '565055',
            patientFirstName: 'RACHEL',
            patientName: 'SAUVE',
            patientGender: 'FEMALE',
            patientAge: 84,
            patientProvenance: null,
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'has_geriatric_record',
                'has_clsc_mental_health_record',
                'has_home_support',
                'has_mental_health_diagnosis',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103017,
            patientId: '3bb6da14-58cf-4f02-9090-13e2bf6d8c06',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 38,
            visitExpectedPlannedDischargedHours: 68,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1019',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1136410',
            patientFirstName: 'SUZANNE',
            patientName: 'BOILEAU',
            patientGender: 'FEMALE',
            patientAge: 95,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more', 'has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 5,
            completedImages: [
                {
                    patientId: '3bb6da14-58cf-4f02-9090-13e2bf6d8c06',
                    uniqueId: '8c08eeb083323c13e15457e55d7adebe',
                    imageDescription: 'MRI HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102791,
            patientId: '8268b0bb-f4dc-47d6-9463-55e0285f7c0b',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 81,
            visitExpectedPlannedDischargedHours: 20,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1015',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1811554',
            patientFirstName: 'MARCELLE',
            patientName: 'ACELIN',
            patientGender: 'FEMALE',
            patientAge: 82,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: '8268b0bb-f4dc-47d6-9463-55e0285f7c0b',
                    uniqueId: 'c906fc9e02459505df2b2239e906b2b9',
                    imageDescription: 'ABDOMEN 1 FILM',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024100068,
            patientId: 'd7f5dc59-369b-4a09-a184-d4b497ba0977',
            visitDiagnosis: 'intracerebral hemorrhage',
            visitLengthOfStaySinceAdmissionHours: 851,
            visitExpectedPlannedDischargedHours: 149,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1021',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '654221',
            patientFirstName: 'MADGE',
            patientName: 'WHYTE',
            patientGender: 'FEMALE',
            patientAge: 82,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'is_75_or_more',
                'is_highly_vulnerable',
                'has_home_support',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 5,
            completedImages: [
                {
                    patientId: 'd7f5dc59-369b-4a09-a184-d4b497ba0977',
                    uniqueId: '95bf5f32797c6ac372a58e27c35a3a4b',
                    imageDescription: 'MRI HEAD C- C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-18T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103019,
            patientId: '9bedf9bd-c607-4daa-93b1-f3d5c373cf85',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 38,
            visitExpectedPlannedDischargedHours: 68,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1007',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '577819',
            patientFirstName: 'NAIM',
            patientName: 'FARHAT',
            patientGender: 'MALE',
            patientAge: 69,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101840,
            patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 377,
            visitExpectedPlannedDischargedHours: 5,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1021',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1810309',
            patientFirstName: 'THONGSAVANH',
            patientName: 'KHAMSOMBOUN',
            patientGender: 'FEMALE',
            patientAge: 71,
            patientProvenance: 'Home',
            patientTerritory: 'Laurentides',
            patientTerritoryAcronym: 'LAU',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: 'd47eee0d2174d8ca59bc411c2e21d6bd',
                    imageDescription: 'MRI HEAD C- C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: 'fa0e4d90b8dc946645ea1d5b64e02d8f',
                    imageDescription: 'CT SCAN ABDOMEN-PELVIS C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: '8a070d3985873303dc3cecc4bc5de5cb',
                    imageDescription: 'MRI ABDOMEN C- C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: 'b8bf286dc2c7e9ce4f07fc2e576d8f0b',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: '8ed14079152e833a1a7d957105598abc',
                    imageDescription:
                        'MRI-IMAGE MANIPULATION (per 5 mins. - max.6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: 'a72692c5-e20d-44cd-865f-b156535481c9',
                    uniqueId: '08ab7f9fa9f08c974df501e19d51a44f',
                    imageDescription: 'CT SCAN THORAX C+ (inc. suprarenals)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
            ],
            completedImagesCount: 6,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102658,
            patientId: '1c528e1c-e191-4dbc-8575-d108196f6404',
            visitDiagnosis: 'bacteremia',
            visitLengthOfStaySinceAdmissionHours: 106,
            visitExpectedPlannedDischargedHours: 188,
            visitFollowingDoctorName: 'DI MAIO SALVATORE',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1013',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1792298',
            patientFirstName: 'DIANE',
            patientName: 'PARTINGTON',
            patientGender: 'FEMALE',
            patientAge: 75,
            patientProvenance: 'Hospital',
            patientTerritory:
                'Estrie – Centre hospitalier universitaire de Sherbrooke',
            patientTerritoryAcronym: 'CHUS',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103175,
            patientId: 'ecbce000-c049-4c70-ac3b-4de81106d840',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 6,
            visitExpectedPlannedDischargedHours: 164,
            visitFollowingDoctorName: 'MAMANE SAMUEL JARRED',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1004',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '171168',
            patientFirstName: 'MEYER MICHEL',
            patientName: 'DE LOUYA',
            patientGender: 'MALE',
            patientAge: 88,
            patientProvenance: null,
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'is_75_or_more',
                'has_mental_health_diagnosis',
                'has_home_support',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: 'ecbce000-c049-4c70-ac3b-4de81106d840',
                    uniqueId: '740c481815d94a8ff1f2e126e3dcf896',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
                {
                    patientId: 'ecbce000-c049-4c70-ac3b-4de81106d840',
                    uniqueId: '43575821ca63c828f89e3f30659194ad',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102451,
            patientId: '574e28cb-b865-46a3-89df-df454cb78754',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 182,
            visitExpectedPlannedDischargedHours: 77,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1012',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1639390',
            patientFirstName: 'FRANCOIS',
            patientName: 'RACINE',
            patientGender: 'MALE',
            patientAge: 89,
            patientProvenance: 'Hospital',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more', 'has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102897,
            patientId: '1fdff0a0-1f74-44b5-9a43-267b0bccd8a4',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 62,
            visitExpectedPlannedDischargedHours: 500,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1013',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '719083',
            patientFirstName: 'ADRIENNE',
            patientName: 'BASTIEN',
            patientGender: 'FEMALE',
            patientAge: 94,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more', 'has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102763,
            patientId: '429bbf50-4f16-4aca-aa0a-723432f74bb8',
            visitDiagnosis: 'paresthesias - numbness',
            visitLengthOfStaySinceAdmissionHours: 87,
            visitExpectedPlannedDischargedHours: 92,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1017',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1811798',
            patientFirstName: 'SERMISE',
            patientName: 'FREJUSTE',
            patientGender: 'FEMALE',
            patientAge: 69,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024092795,
            patientId: '9577f32a-2bee-4e70-a926-dc0818bd19ed',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 2889,
            visitExpectedPlannedDischargedHours: 149,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1016',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '165630',
            patientFirstName: 'ALAIN',
            patientName: 'BARCHICHAT',
            patientGender: 'MALE',
            patientAge: 67,
            patientProvenance: 'Rehab',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_mental_health_diagnosis',
                'is_highly_vulnerable',
                'has_home_support',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: '9577f32a-2bee-4e70-a926-dc0818bd19ed',
                    uniqueId: 'f07160ce4550d2f9b5243d615fbda54c',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '9577f32a-2bee-4e70-a926-dc0818bd19ed',
                    uniqueId: 'c0b4e657908cdd95a52849813d21fc1b',
                    imageDescription:
                        'ECHO SURFACE - OTHER MISCELLANEOUS REGION',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: '9577f32a-2bee-4e70-a926-dc0818bd19ed',
                    uniqueId: 'b35ee95b97da437797fa45bdc581a306',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '9577f32a-2bee-4e70-a926-dc0818bd19ed',
                    uniqueId: '113a9b912c09898f9b4e5be2d3af56e1',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
            ],
            completedImagesCount: 4,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024101950,
            patientId: '41b40ef7-b30c-43a7-a5c2-48133b218ba3',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 352,
            visitExpectedPlannedDischargedHours: 149,
            visitFollowingDoctorName: 'SCHUR SOLON',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1005',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '788313',
            patientFirstName: 'FLINT',
            patientName: 'KAYA',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['has_home_support'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 5,
            completedImages: [
                {
                    patientId: '41b40ef7-b30c-43a7-a5c2-48133b218ba3',
                    uniqueId: 'b1c468268718890ec162c839fbe72bc5',
                    imageDescription: 'MRI HEAD C- C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024100765,
            patientId: 'e2051ea8-3a92-456a-a2ec-e8eef2b9cebd',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 684,
            visitExpectedPlannedDischargedHours: 677,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1018',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1808285',
            patientFirstName: 'CELESTINA',
            patientName: 'GALLO',
            patientGender: 'FEMALE',
            patientAge: 100,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more', 'is_highly_vulnerable'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102600,
            patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 137,
            visitExpectedPlannedDischargedHours: 509,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1011',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1811436',
            patientFirstName: 'JACQUES',
            patientName: 'GOSSELIN',
            patientGender: 'MALE',
            patientAge: 73,
            patientProvenance: 'Hospital',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 4,
            completedImages: [
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: 'e18f38185a0736ba7aa839e90d0141a5',
                    imageDescription: 'CT SCAN LOWER EXTREMITIES C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '636cc03218600a0ade99de142eae02a0',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '8a172743628a4e9d52a7c0366c8bd691',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-20T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '29c772f75b03b9106abd2cb236afe1a1',
                    imageDescription: 'CT SCAN THORAX C+ (inc. suprarenals)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '2179949ecdf3956ec5ae9f4b6c60cd72',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-20T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '50e8d76f9d85d86876b38a8db12cfbbf',
                    imageDescription: 'CT SCAN THORAX C+ (inc. suprarenals)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-20T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: 'dd647f60467cf9c6b2b92dc86514cf52',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-20T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: '89050b3f282d2efe6f40f974c6207ddb',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-19T00:00:00',
                },
                {
                    patientId: 'd15d52eb-faeb-4e0e-8458-9a1e7fac8efe',
                    uniqueId: 'aa9af266ddaa68f92fc8cb5ae944e546',
                    imageDescription: 'CT SCAN PELVIS C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
            ],
            completedImagesCount: 9,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102901,
            patientId: '1500132e-1085-4775-bb80-6dba59f860d7',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 60,
            visitExpectedPlannedDischargedHours: 101,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1006',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1811899',
            patientFirstName: 'ALICE',
            patientName: 'BOURQUE LANGERVIN',
            patientGender: 'FEMALE',
            patientAge: 78,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024099157,
            patientId: 'f0cfe620-4d2c-49fb-ac11-b67cec20ea1b',
            visitDiagnosis: 'myelopathy / spinal cord disease',
            visitLengthOfStaySinceAdmissionHours: 1064,
            visitExpectedPlannedDischargedHours: 149,
            visitFollowingDoctorName: 'SCHUR SOLON',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1003',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '99754',
            patientFirstName: 'ISAY',
            patientName: 'BENZWY',
            patientGender: 'MALE',
            patientAge: 78,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024098480,
            patientId: 'e6981ae1-b7ce-4e1f-9d82-973efcec1ce3',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 1238,
            visitExpectedPlannedDischargedHours: 677,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1022',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1546008',
            patientFirstName: 'VIET NINH',
            patientName: 'NGUYEN',
            patientGender: 'MALE',
            patientAge: 87,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'is_highly_vulnerable',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: 'e6981ae1-b7ce-4e1f-9d82-973efcec1ce3',
                    uniqueId: '30d0a44a13aa0a6697c785e69e5a7b12',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-01T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103035,
            patientId: '1909f8aa-d2f6-418e-b87d-b3a0f2c8d723',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 36,
            visitExpectedPlannedDischargedHours: 101,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1010',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '409806',
            patientFirstName: 'WILLIAM',
            patientName: 'BELL',
            patientGender: 'MALE',
            patientAge: 88,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'is_75_or_more',
                'has_geriatric_record',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103042,
            patientId: 'a55cd56c-85fc-4e4a-b72e-be823602406d',
            visitDiagnosis: 'intracerebral hemorrhage',
            visitLengthOfStaySinceAdmissionHours: 34,
            visitExpectedPlannedDischargedHours: 5,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1014',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1645022',
            patientFirstName: 'FRANCES',
            patientName: 'RUBIN',
            patientGender: 'FEMALE',
            patientAge: 88,
            patientProvenance: 'Nursing home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 4,
            completedImages: [
                {
                    patientId: 'a55cd56c-85fc-4e4a-b72e-be823602406d',
                    uniqueId: 'e0c95da4d47e951c1480dcdc72d2ddcc',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
                {
                    patientId: 'a55cd56c-85fc-4e4a-b72e-be823602406d',
                    uniqueId: 'f41a5f0bd42fb60487ca971a4d341ae9',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-23T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103061,
            patientId: 'c9998f43-6295-4059-8a10-eef5af1065a7',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 31,
            visitExpectedPlannedDischargedHours: 5,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1020',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '740852',
            patientFirstName: 'ALAIN',
            patientName: 'PINSONNEAULT',
            patientGender: 'MALE',
            patientAge: 63,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102735,
            patientId: '11258136-5510-48ad-a2f0-a1521414e11d',
            visitDiagnosis:
                'transnasal transsphemoidal excision pituitry microadenoma',
            visitLengthOfStaySinceAdmissionHours: 95,
            visitExpectedPlannedDischargedHours: 5,
            visitFollowingDoctorName: 'DI MAIO SALVATORE',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1002',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1795493',
            patientFirstName: 'MOHAMMAD MUKHTAR',
            patientName: 'ALIF',
            patientGender: 'MALE',
            patientAge: 49,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: null,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024102473,
            patientId: 'db53d6dd-ebca-489c-913f-5fd73df63405',
            visitDiagnosis: 'cva-cerebrovascular accident',
            visitLengthOfStaySinceAdmissionHours: 178,
            visitExpectedPlannedDischargedHours: 125,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1001',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '474673',
            patientFirstName: 'ROSE',
            patientName: 'EMBER',
            patientGender: 'FEMALE',
            patientAge: 94,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['has_home_support', 'is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: null,
            completedImages: [
                {
                    patientId: 'db53d6dd-ebca-489c-913f-5fd73df63405',
                    uniqueId: '31ffc7e07693cfff35daf73b605a73d5',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-18T00:00:00',
                },
                {
                    patientId: 'db53d6dd-ebca-489c-913f-5fd73df63405',
                    uniqueId: '526e30b580346472c1790043814966db',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-18T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
        {
            id: 2024103030,
            patientId: '1af3e024-3ef0-460d-86ec-a29c8cea91e4',
            visitDiagnosis: 'intracerebral hemorrhage',
            visitLengthOfStaySinceAdmissionHours: 36,
            visitExpectedPlannedDischargedHours: 140,
            visitFollowingDoctorName: 'ALTMAN ROBERT DANIEL',
            wardSection: 'Neurology',
            locationUnitName: 'K10',
            locationRoomName: 'K1008',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '417157',
            patientFirstName: 'KENNETH',
            patientName: 'MOORE',
            patientGender: 'MALE',
            patientAge: 61,
            patientProvenance: 'Home',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-25T08:22:02.227201',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
            readLabsCount: 0,
            allLabsAreRead: false,
            predictedLengthOfStayCategory: 'less than 2 days',
            losPrediction: {
                patientId: 'e3330a4f-0c8e-4165-93bf-370411d7a7b0',
                predictedLengthOfStayDays: '4.174779134309523',
                predictedLengthOfStayCategory: '4-8 days',
                predictionTime: '2024-11-08T13:02:10Z',
                version: 'GB_V1',
            },
            dischargeFactors: ['Test'],
        },
    ],
}

export const WARD_SSU_MOCK = {
    wards: [
        {
            id: 2024101922,
            patientId: '107d7260-e6e3-4082-9f96-a69992aab65d',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 12,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C633',
            locationBedName: 'A',
            actualTransferStatus: 'Signed Discharge',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
            ],
            patientRecord: '834603',
            patientFirstName: 'BOZENA',
            patientName: 'PAJAKOWSKA',
            patientGender: 'FEMALE',
            patientAge: 71,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 8,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024100670,
            patientId: '9fbf7ade-67a5-4ec4-a7c4-4273a608e6cd',
            visitDiagnosis: 'cellulitis',
            visitLengthOfStaySinceAdmissionHours: 355,
            visitExpectedPlannedDischargedHours: 105,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C637',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1808747',
            patientFirstName: 'JACQUES',
            patientName: 'HANDFIELD',
            patientGender: 'MALE',
            patientAge: 74,
            patientProvenance: 'Home',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024099738,
            patientId: '4543ed6f-07d5-4a63-92d3-c2981bf77fbf',
            visitDiagnosis: 'fx cervical vertebra, closed',
            visitLengthOfStaySinceAdmissionHours: 573,
            visitExpectedPlannedDischargedHours: 57,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C636',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1807259',
            patientFirstName: 'LILI',
            patientName: 'MARSOLAIS',
            patientGender: 'FEMALE',
            patientAge: 99,
            patientProvenance: 'Other',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: [
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: '2fecf7cd17ea4418eb78d27be86a875c',
                    imageDescription:
                        'CORONARY ANGIOPLASTY + CORONOGRAPHY 1- 2 SEGMENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: 'c204dfcfbcaaa567a0f255c11730a014',
                    imageDescription: '2 MEDICATED STENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: '2386ce0870cb3cbee92fd38b2913dde9',
                    imageDescription: 'ANGIOGRAPHY NON CORONARY',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: '5f497ec76e2b6af5c91e76d1011dd249',
                    imageDescription: 'GENERAL ANAESTHESIA',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: '2a9f5ff8189439ff68fac167ef9ca84f',
                    imageDescription: 'THROMBECTOMY CATHETER',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: '12ddadd34c27d9a73b8b88554a59f39c',
                    imageDescription:
                        'ULTRASOUND - SURFACE-  DURING HEART CATH',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: 'f4e0b7fd1aa8842407590cf47709c22b',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: 'a233fbe79b0644d2ae92c34cf30f4ad7',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: 'cc7dccfa-6107-47e3-92d5-f9426f12aa8b',
                    uniqueId: 'c8d0c53c8593d880e65bacd1a7cd866d',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
            ],
            completedImagesCount: 9,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101451,
            patientId: '859b8d7f-8074-498b-8bac-8b634a5e23f4',
            visitDiagnosis: 'sepsis - septicemia',
            visitLengthOfStaySinceAdmissionHours: 137,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C641',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809877',
            patientFirstName: 'DIANE',
            patientName: 'COLLETTE',
            patientGender: 'FEMALE',
            patientAge: 84,
            patientProvenance: 'Nursing home',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101760,
            patientId: '9e8b666b-ef23-4a6e-85f1-f1cfb035c89f',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 44,
            visitExpectedPlannedDischargedHours: 153,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C648',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1210776',
            patientFirstName: 'WILLIAM',
            patientName: 'GUERIN',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 4,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101432,
            patientId: 'cb875737-67e0-4aee-ba8e-d643156a1c70',
            visitDiagnosis: 'osteomyelitis',
            visitLengthOfStaySinceAdmissionHours: 141,
            visitExpectedPlannedDischargedHours: 345,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C634',
            locationBedName: 'B',
            actualTransferStatus: 'Signed Discharge',
            transferStatuses: [
                {
                    name: 'Signed Discharge',
                    rank: 1,
                },
            ],
            patientRecord: '1430133',
            patientFirstName: 'PULENDRAN',
            patientName: 'PUTHIRASIGAMANI',
            patientGender: 'MALE',
            patientAge: 70,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 6,
            completedImages: [
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: '77e90261afd9272652028349a9bf6155',
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-16T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: '5dda7167b448ec9de1052cde7b70bcec',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: 'eb86739291bd224620dec695d16c8ee6',
                    imageDescription:
                        'MRI-IMAGE MANIPULATION (per 5 mins. - max.6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: '38306262f6b2636b0ddf29400df02737',
                    imageDescription: 'CT SCAN HEAD C-',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: 'b7c2b5369cb94a7ac082b33fede1cbfd',
                    imageDescription: 'MRI CARDIAC C-C+',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-15T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: '96387b0141a240417b42e1d3bdd1322b',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-01T00:00:00',
                },
                {
                    patientId: '014e8ff1-10e9-4b14-a3d7-ae32f6b093bf',
                    uniqueId: 'a8eebfc09564c158f3505dd313197ee9',
                    imageDescription:
                        'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 7,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101276,
            patientId: 'a3e90403-d9d7-4515-902a-59e3103b72c0',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 185,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C638',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1099519',
            patientFirstName: 'SOTIRIOS',
            patientName: 'TSAPARAS',
            patientGender: 'MALE',
            patientAge: 89,
            patientProvenance: 'Home',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2023116278,
            patientId: 'c3f49393-c49c-45eb-a7c2-542f5040753f',
            visitDiagnosis: "crohn's disease",
            visitLengthOfStaySinceAdmissionHours: 4889,
            visitExpectedPlannedDischargedHours: 225,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C642',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '365736',
            patientFirstName: 'DEBRA HEMA',
            patientName: 'MAHABIR',
            patientGender: 'FEMALE',
            patientAge: 59,
            patientProvenance: 'Clinic',
            patientTerritory: 'Montérégie Ouest',
            patientTerritoryAcronym: 'MRO',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 3,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024098581,
            patientId: '0a5f19a6-24e9-46ac-a06e-caa8d5a044d8',
            visitDiagnosis: 'generalized edema',
            visitLengthOfStaySinceAdmissionHours: 864,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C636',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1804575',
            patientFirstName: 'MARIE-PAULE',
            patientName: 'ST-CYR',
            patientGender: 'FEMALE',
            patientAge: 86,
            patientProvenance: 'Clinic',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'is_highly_vulnerable',
                'is_75_or_more',
                'has_home_support',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 4,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101769,
            patientId: '79b07f14-f743-4f04-bd46-6a7b3f5c14ff',
            visitDiagnosis: 'weakness / fatigue / deterioration',
            visitLengthOfStaySinceAdmissionHours: 42,
            visitExpectedPlannedDischargedHours: 129,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C632',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '92000',
            patientFirstName: 'SARITA',
            patientName: 'GOZLAN',
            patientGender: 'FEMALE',
            patientAge: 74,
            patientProvenance: 'Nursing home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 5,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024100198,
            patientId: 'b40fddf2-d1cd-4b58-84e0-3d1806961ffb',
            visitDiagnosis: 'copd exacerbation',
            visitLengthOfStaySinceAdmissionHours: 475,
            visitExpectedPlannedDischargedHours: 23,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C640',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '72605',
            patientFirstName: 'ISAAC',
            patientName: 'RAFFOUL-HARARI',
            patientGender: 'MALE',
            patientAge: 92,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'has_home_support',
                'has_mental_health_diagnosis',
                'has_vulnerability_signals',
                'is_75_or_more',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 1,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101483,
            patientId: 'db1c67b7-b97e-4099-8bfc-b2af5cfd426d',
            visitDiagnosis: 'myalgia',
            visitLengthOfStaySinceAdmissionHours: 115,
            visitExpectedPlannedDischargedHours: 273,
            visitFollowingDoctorName: 'MOTT SEBASTIAN',
            wardSection: 'SSU',
            locationUnitName: 'C61',
            locationRoomName: 'C646',
            locationBedName: 'B',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1092025',
            patientFirstName: 'LEON J',
            patientName: 'MICHEL',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance: 'Home',
            patientTerritory: 'Montérégie Centre',
            patientTerritoryAcronym: 'MRC',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 5,
            completedLabsCount: 2,
            completedImages: [
                {
                    patientId: '0e68ce32-8bc0-46ac-a2bc-5ad196d25569',
                    uniqueId: '93ce313d04fc05f6ff413b6b48f2ea68',
                    imageDescription: 'DOPPLER VEINEUX MEMBRE SUPÉRIEUR',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
                {
                    patientId: '0e68ce32-8bc0-46ac-a2bc-5ad196d25569',
                    uniqueId: '9fe5a3bba33c2fcbd8c160f24cde5682',
                    imageDescription: 'DOPPLER VEINEUX MEMBRE INFÉRIEUR',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-22T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
    ],
}

export const WARD_MSISU_MOCK = {
    wards: [
        {
            id: 2024094985,
            patientId: '69de0bfb-0f5f-463a-b5dc-6ff9ada6ebab',
            visitDiagnosis: 'excision lesion nasopharyngeal',
            visitLengthOfStaySinceAdmissionHours: 1894,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0127',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1431981',
            patientFirstName: 'GEORGE',
            patientName: 'MACHHOUR',
            patientGender: 'MALE',
            patientAge: 77,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Nord de l'Île de Montréal",
            patientTerritoryAcronym: 'CNMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 3,
            completedImages: [
                {
                    patientId: '69de0bfb-0f5f-463a-b5dc-6ff9ada6ebab',
                    uniqueId: 2024131634,
                    imageDescription: 'CT SCAN THORAX C- (inc. suprarenals)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: true,
        },
        {
            id: 2024093304,
            patientId: '51f99c1b-23b9-4902-95bc-9732aca5b774',
            visitDiagnosis: 'neoplasm of breast',
            visitLengthOfStaySinceAdmissionHours: 2370,
            visitExpectedPlannedDischargedHours: 355,
            visitFollowingDoctorName: 'WONG EVAN',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0131',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1263746',
            patientFirstName: 'ANNY',
            patientName: 'KARIRE',
            patientGender: 'FEMALE',
            patientAge: 56,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 9,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101716,
            patientId: 'ab2d7cbd-02a5-4fe9-86c3-a5c639bc438e',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 60,
            visitExpectedPlannedDischargedHours: 177,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0130',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1808071',
            patientFirstName: 'MONIQUE',
            patientName: 'LAQUERRE',
            patientGender: 'FEMALE',
            patientAge: 71,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 2,
            completedLabsCount: 17,
            completedImages: [
                {
                    patientId: 'a0f7f602-0f69-4d15-ad8a-e3c597b5fdc9',
                    uniqueId: '23569f2b914c22d4b5f579f972604bec',
                    imageDescription: '2 MEDICATED STENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'a0f7f602-0f69-4d15-ad8a-e3c597b5fdc9',
                    uniqueId: 'd89556230cc346b17338925c96cd1b24',
                    imageDescription:
                        'CORONARY ANGIOPLASTY + CORONOGRAPHY 1- 2 SEGMENTS',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'a0f7f602-0f69-4d15-ad8a-e3c597b5fdc9',
                    uniqueId: 'd29a2814aae870327aad6f1d343f814a',
                    imageDescription:
                        'ULTRASOUND INTRA - ARTERIAL OR INTRA - CARDIAC',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
                {
                    patientId: 'a0f7f602-0f69-4d15-ad8a-e3c597b5fdc9',
                    uniqueId: 'ec6bcfbd32981a7b608eaa4df5dee1b8',
                    imageDescription: 'STENT - NOT DEPLOYED',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-10-21T00:00:00',
                },
            ],
            completedImagesCount: 4,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: true,
        },
        {
            id: 2023107440,
            patientId: 'fb8194d5-6f06-4942-8057-337c49fa55ff',
            visitDiagnosis: 'orchitis / epididymitis',
            visitLengthOfStaySinceAdmissionHours: 7374,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0114',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1602275',
            patientFirstName: 'SONG',
            patientName: 'SU',
            patientGender: 'MALE',
            patientAge: 61,
            patientProvenance: 'Home',
            patientTerritory: null,
            patientTerritoryAcronym: null,
            patientCareFactors: [
                'is_living_alone',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 10,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101836,
            patientId: '9789d277-33f9-4f37-97e8-55976fbf633b',
            visitDiagnosis: 'post-op/wound infection',
            visitLengthOfStaySinceAdmissionHours: 29,
            visitExpectedPlannedDischargedHours: 201,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0129',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '599722',
            patientFirstName: 'EFTERPI',
            patientName: 'KAROUNOS',
            patientGender: 'FEMALE',
            patientAge: 57,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 6,
            completedLabsCount: 10,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: true,
        },
        {
            id: 2024101907,
            patientId: '7fc6e4f4-1f8f-417a-abde-ce6d08ee899a',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 14,
            visitExpectedPlannedDischargedHours: 225,
            visitFollowingDoctorName: 'WONG EVAN',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0132',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '260863',
            patientFirstName: 'GEORGE',
            patientName: 'WISEMAN',
            patientGender: 'MALE',
            patientAge: 79,
            patientProvenance: 'Domicile sans soins à domicile',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 9,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024090800,
            patientId: '66a2b25f-2a18-4551-a974-0993320413e2',
            visitDiagnosis: 'respiratory failure',
            visitLengthOfStaySinceAdmissionHours: 3077,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0112',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '622222',
            patientFirstName: 'PAUL',
            patientName: 'HARARY',
            patientGender: 'MALE',
            patientAge: 75,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [
                'is_75_or_more',
                'has_mental_health_diagnosis',
                'has_home_support',
                'has_vulnerability_signals',
            ],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 1,
            completedImages: [
                {
                    patientId: '66a2b25f-2a18-4551-a974-0993320413e2',
                    uniqueId: 2024131782,
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-29T00:00:00',
                },
            ],
            completedImagesCount: 1,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024101046,
            patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
            visitDiagnosis: 'ami - myocardial infarct acute',
            visitLengthOfStaySinceAdmissionHours: 241,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0124',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '312616',
            patientFirstName: 'ARMAND',
            patientName: 'BEN-MOHA',
            patientGender: 'MALE',
            patientAge: 80,
            patientProvenance: 'Home',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: ['is_75_or_more'],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 7,
            completedLabsCount: 17,
            completedImages: [
                {
                    patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
                    uniqueId: 2024132217,
                    imageDescription: 'CHEST (2 FILMS  OR LESS)',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-29T00:00:00',
                },
                {
                    patientId: '16aab960-7f40-443e-acf1-84ba3a1960a7',
                    uniqueId: 2024028016,
                    imageDescription:
                        'LEFT HEART CATHETERIZATION AND CORONAROGRAPHY',
                    imageStatus: 'Confirmed',
                    imageCreationDatetime: '2024-09-30T00:00:00',
                },
            ],
            completedImagesCount: 2,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024100759,
            patientId: '1550e341-30fb-4d83-8b3b-f163c9450177',
            visitDiagnosis: 'chf - congestive heart failure',
            visitLengthOfStaySinceAdmissionHours: 336,
            visitExpectedPlannedDischargedHours: 18,
            visitFollowingDoctorName: 'PERETZ-LAROCHELLE MAUDE',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0134',
            locationBedName: 'A',
            actualTransferStatus: 'Planned transfer',
            transferStatuses: [
                {
                    name: 'Planned transfer',
                    rank: 2,
                },
            ],
            patientRecord: '1808821',
            patientFirstName: 'MANON',
            patientName: 'DES LAURIERS',
            patientGender: 'FEMALE',
            patientAge: 55,
            patientProvenance: 'Home',
            patientTerritory: 'Laval',
            patientTerritoryAcronym: 'LAV',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 3,
            completedLabsCount: 12,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024100878,
            patientId: '338f4fbf-1219-46b2-b18e-9d1d242aa047',
            visitDiagnosis: 'leukemia',
            visitLengthOfStaySinceAdmissionHours: 311,
            visitExpectedPlannedDischargedHours: 858,
            visitFollowingDoctorName: 'WONG EVAN',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0125',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1809048',
            patientFirstName: 'FARAN',
            patientName: 'ZAHID',
            patientGender: 'MALE',
            patientAge: 20,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Centre Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'CCOMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 11,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
        {
            id: 2024099965,
            patientId: 'a9462d14-f283-46e5-bdf0-4a1785cdab88',
            visitDiagnosis: 'pneumonia',
            visitLengthOfStaySinceAdmissionHours: 524,
            visitExpectedPlannedDischargedHours: 0,
            visitFollowingDoctorName: 'WONG EVAN',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0123',
            locationBedName: 'A',
            actualTransferStatus: 'medically active',
            transferStatuses: [],
            patientRecord: '1238564',
            patientFirstName: 'JERZY',
            patientName: 'CIESIELSKI',
            patientGender: 'MALE',
            patientAge: 69,
            patientProvenance: 'Home',
            patientTerritory: "Ouest de l'Île de Montréal",
            patientTerritoryAcronym: 'COMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: null,
            completedLabsCount: 2,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: true,
        },
        {
            id: 2024101663,
            patientId: '3e94db46-48e9-44c8-b773-8e7463a4ecd6',
            visitDiagnosis: 'cabg x 3 (coronary artery bypass graft)',
            visitLengthOfStaySinceAdmissionHours: 65,
            visitExpectedPlannedDischargedHours: 153,
            visitFollowingDoctorName: 'JAYARAMAN DEV',
            wardSection: 'MSICU',
            locationUnitName: 'K1IC',
            locationRoomName: 'K0120',
            locationBedName: 'A',
            actualTransferStatus: 'Pending Transfer',
            transferStatuses: [
                {
                    name: 'Planned transfer',
                    rank: 2,
                },
                {
                    name: 'Pending Transfer',
                    rank: 4,
                },
            ],
            patientRecord: '1807443',
            patientFirstName: 'NADIA',
            patientName: 'BENZINE',
            patientGender: 'FEMALE',
            patientAge: 62,
            patientProvenance:
                'CH soins courte durée, public (acute care Hospital)',
            patientTerritory: "Est de l'Île de Montréal",
            patientTerritoryAcronym: 'EMTL',
            patientCareFactors: [null],
            vitalsEwsNicuResults: null,
            pendingLabsCount: 1,
            completedLabsCount: 7,
            completedImages: null,
            completedImagesCount: null,
            pendingImages: null,
            pendingImagesCount: null,
            lastModifiedDate: '2024-10-10T18:42:33.603079',
            communityInvolved: null,
            isolationStatus: null,
            isolationStatuses: ['MRSA', 'OTHER'],
            visitLengthOfStayWarning: false,
            isOffService: false,
        },
    ],
}

export const LABS_MOCK_DATA: LabsDataResponse = {
    labs: [
        {
            id: '46c851915daf54fd1efb6652852096a4',
            requestType: 'W3123345:MDIFF',
            requestName: 'W3123345:MDIFF Differential (Manual)',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'CORWC',
            observationDescription: 'Corrected WBC',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '11e0e69a92f68a851010783e74ee98f5',
            requestType: 'W3123345:MDIFF',
            requestName: 'W3123345:MDIFF Differential (Manual)',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'LIMIT',
            observationDescription: 'Smear cell count of 100',
            observationValue: '100',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '9e31cb7a58c05825b82308c67d5fe099',
            requestType: 'W3123345:MDIFF',
            requestName: 'W3123345:MDIFF Differential (Manual)',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'SMEAR',
            observationDescription: 'SMEAR1',
            observationValue: 'En cours',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'ed6b157f5b2c85f47b0cb6e3beaa423d',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'SCAN_',
            observationDescription: ' ',
            observationValue: 'En cours',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'e968aecd5bb09b9071eb1b17c316e97a',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'DIFF_',
            observationDescription: 'Manual Smear',
            observationValue: 'to follow',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '16c3ff46602fbc6233df24ec39aa7aaf',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'PLTES',
            observationDescription: 'Platelet Estimation',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'bfcf4c6f5c9aa751f111d5b8cc7d8769',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'LAPLT',
            observationDescription: 'Large Platelets',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'cf7315958d75187fd4082a922b4f3bdb',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'GIPLT',
            observationDescription: 'Giant Platelets',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'eb2c880737c4f48959315e68ab6aeeab',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'PLTCL',
            observationDescription: 'Platelet Clumps',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '00dd8736a8dcceadb8aa2f85409e1ba8',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'RAGG',
            observationDescription: 'RBC Agglutination',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'd77b1f3ff1f4fc5c2d45a6c9b690ce00',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'MISC',
            observationDescription: '',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '810fc39239bd0404b7f5ac297705c1df',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'DCHK',
            observationDescription: 'Dummy test used for RBS rule',
            observationValue: 'CHECK',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '2e84e600a2ea98acd8f7b1cf46a6b697',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'DIFFX',
            observationDescription: ' ',
            observationValue: '-',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '3fd3450547c293272745af69be142418',
            requestType: 'W3123345:MDIFF:SDIFF',
            requestName: 'W3123345:MDIFF:SDIFF ',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-12T17:52:00+00:00',
            observationType: 'DIFFR',
            observationDescription: '    Reviewed by:',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'f545bdd8088a88df2cc39cce3d8b8656',
            requestType: 'W3143112:OSMRU',
            requestName: 'W3143112:OSMRU Osmolality Urine',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-14T15:18:00+00:00',
            observationType: 'OSMUR',
            observationDescription: 'Osmolality Urine',
            observationValue: 'En cours',
            observationUnit: 'mOsm/kg',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '215e9e587b4871b69fb21b9081dc0665',
            requestType: 'W3143112:OSMRU',
            requestName: 'W3143112:OSMRU Osmolality Urine',
            requestStatus: 'Pending',
            observationDatetime: '2024-11-14T15:18:00+00:00',
            observationType: 'OSMU2',
            observationDescription: 'Osmolality duplicate Urine',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '0f928cbc32c018a7dd66e2338adec325',
            requestType: 'W3170091:AGAP',
            requestName: 'W3170091:AGAP ',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'AGAPI',
            observationDescription: 'Anion Gap',
            observationValue: '8',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: true,
            observationRange1: '7',
            observationRange2: '15',
            observationRangeCursor: '0',
        },
        {
            id: '1e210c24fae47d603f24ba3c70e7ff11',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'UREAI',
            observationDescription: 'Urea',
            observationValue: '5.8',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: true,
            observationRange1: '3.2',
            observationRange2: '8.5',
            observationRangeCursor: '0',
        },
        {
            id: '73efed7267699fce3a9eb6d7dc02839f',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'NAI',
            observationDescription: 'Sodium',
            observationValue: '125',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: 'Very low',
            isRead: true,
            observationRange1: '134',
            observationRange2: '144',
            observationRangeCursor: '-1',
        },
        {
            id: 'f9f2352d5acde4445bd9a71e10d6c6af',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'KI',
            observationDescription: 'Potassium',
            observationValue: '4.4',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '3.5',
            observationRange2: '5.5',
            observationRangeCursor: '1',
        },
        {
            id: 'b6e75a7043958ed549083e0aa6267eab',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'CLI',
            observationDescription: 'Chloride',
            observationValue: '93',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: 'Low',
            isRead: true,
            observationRange1: '98',
            observationRange2: '108',
            observationRangeCursor: '-1',
        },
        {
            id: '7ec1643138c773fafedbe0fc814f9da1',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'GLUI',
            observationDescription: 'Glucose',
            observationValue: '5.4',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: true,
            observationRange1: '3.9',
            observationRange2: '7.7',
            observationRangeCursor: '0',
        },
        {
            id: 'e491ac464bcf7ccaa7609f5da4502e8b',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'CREI',
            observationDescription: 'Creatinine',
            observationValue: '85',
            observationUnit: 'umol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '55',
            observationRange2: '120',
            observationRangeCursor: '1',
        },
        {
            id: '0fe2d2f65fec6944ef479b186987ec85',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'BICI',
            observationDescription: 'Bicarbonate',
            observationValue: '24',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '22',
            observationRange2: '31',
            observationRangeCursor: '0',
        },
        {
            id: '96ac39f7d0cc5daf7ac32853810c816d',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'LIP',
            observationDescription: 'Lipemia',
            observationValue: 'Normal',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '4e5b9e2214d858c4d8bdecd3a278f25d',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'HEM',
            observationDescription: 'Hemolysis',
            observationValue: '1+',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '73d1c1a16e60154dadb7116f8d9293b9',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'ICT',
            observationDescription: 'Icteric',
            observationValue: 'Normal',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '60c8b91bdf79aab393bdb108a740a4c0',
            requestType: 'W3170091:CHEM7',
            requestName: 'W3170091:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T08:19:00+00:00',
            observationType: 'GLUII',
            observationDescription: 'Glucose-downloaded-Interface',
            observationValue: '5.4',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '3.9',
            observationRange2: '5.5',
            observationRangeCursor: '0',
        },
        {
            id: '83bf4b070746475a90f4ea92545baadd',
            requestType: 'W3170793:CLRU',
            requestName: 'W3170793:CLRU Chloride (random urine)',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T12:11:00+00:00',
            observationType: 'CLUR',
            observationDescription: 'Chloride (random)',
            observationValue: '83',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '246a49f2722dca8893cd6ebb6d53d944',
            requestType: 'W3170793:KRU',
            requestName: 'W3170793:KRU Potassium (random urine)',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T12:11:00+00:00',
            observationType: 'KUR',
            observationDescription: 'Potassium (random)',
            observationValue: '27',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '3db7a560a0631b645d249ab51d79839a',
            requestType: 'W3170793:NARU',
            requestName: 'W3170793:NARU Sodium (random urine)',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T12:11:00+00:00',
            observationType: 'NAUR',
            observationDescription: 'Sodium (random)',
            observationValue: '106',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'b8fe9e13bc5721610718649849dc5e7c',
            requestType: 'W3170793:OSMRU',
            requestName: 'W3170793:OSMRU Osmolality Urine',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T13:27:00+00:00',
            observationType: 'OSMUR',
            observationDescription: 'Osmolality Urine',
            observationValue: '415',
            observationUnit: 'mOsm/kg',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '50',
            observationRange2: '1200',
            observationRangeCursor: '0',
        },
        {
            id: '62f3b0c2069b85fb182d7281697c4b9d',
            requestType: 'W3170793:OSMRU',
            requestName: 'W3170793:OSMRU Osmolality Urine',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T13:27:00+00:00',
            observationType: 'OSMU2',
            observationDescription: 'Osmolality duplicate Urine',
            observationValue: '.',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: 'c6659d79b457fa16f8b0805be40029d0',
            requestType: 'W3171452:AGAP',
            requestName: 'W3171452:AGAP ',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'AGAPI',
            observationDescription: 'Anion Gap',
            observationValue: '7',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '7',
            observationRange2: '15',
            observationRangeCursor: '0',
        },
        {
            id: 'cdddcd602f6eab4ab99ab387f2309412',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'UREAI',
            observationDescription: 'Urea',
            observationValue: '7.1',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '3.2',
            observationRange2: '8.5',
            observationRangeCursor: '0',
        },
        {
            id: 'f80d9b1547e03c847c7a84c5bbf0d84e',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'ICT',
            observationDescription: 'Icteric',
            observationValue: 'Normal',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '20816ef53926ac4691f2ceea92aa59ca',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'GLUII',
            observationDescription: 'Glucose-downloaded-Interface',
            observationValue: '5.9',
            observationUnit: '',
            observationRange: '',
            observationFlags: 'High',
            isRead: null,
            observationRange1: '3.9',
            observationRange2: '5.5',
            observationRangeCursor: '1',
        },
        {
            id: '00453780e31699382a986c44b67265be',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'NAI',
            observationDescription: 'Sodium',
            observationValue: '123',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: 'Very low',
            isRead: null,
            observationRange1: '134',
            observationRange2: '144',
            observationRangeCursor: '-1',
        },
        {
            id: 'fe5e02bfa7d868edcacbb4c3fac978d4',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'KI',
            observationDescription: 'Potassium',
            observationValue: '4.8',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '3.5',
            observationRange2: '5.5',
            observationRangeCursor: '0',
        },
        {
            id: 'e70d82d02f82085c885e67e12b3adb34',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'CLI',
            observationDescription: 'Chloride',
            observationValue: '92',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: 'Low',
            isRead: null,
            observationRange1: '98',
            observationRange2: '108',
            observationRangeCursor: '-1',
        },
        {
            id: 'c17901bbb7690b87c70a93a0933c2181',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'GLUI',
            observationDescription: 'Glucose',
            observationValue: '5.9',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '3.9',
            observationRange2: '7.7',
            observationRangeCursor: '0',
        },
        {
            id: 'fb28ade25bcfde1f98bc9a1e9a91e68d',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'CREI',
            observationDescription: 'Creatinine',
            observationValue: '79',
            observationUnit: 'umol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '55',
            observationRange2: '120',
            observationRangeCursor: '0',
        },
        {
            id: 'fe282e2883facf3e8e64c35b623d1eb3',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'BICI',
            observationDescription: 'Bicarbonate',
            observationValue: '24',
            observationUnit: 'mmol/L',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '22',
            observationRange2: '31',
            observationRangeCursor: '0',
        },
        {
            id: '35b6ff31d1b7c837f5d98a329732bb8f',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'LIP',
            observationDescription: 'Lipemia',
            observationValue: 'Normal',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
        {
            id: '71b84f7be8da4f6a2c5934ca12920a5f',
            requestType: 'W3171452:CHEM7',
            requestName: 'W3171452:CHEM7 CHEM7',
            requestStatus: 'Done',
            observationDatetime: '2024-11-17T18:13:00+00:00',
            observationType: 'HEM',
            observationDescription: 'Hemolysis',
            observationValue: 'Normal',
            observationUnit: '',
            observationRange: '',
            observationFlags: '',
            isRead: null,
            observationRange1: '',
            observationRange2: '',
            observationRangeCursor: '0',
        },
    ],
}

export const TRANSLATION_MOCK = {
    translations: [
        {
            domain: 'care_factors',
            category: 'has_mental_health_diagnosis',
            french: 'Diagnostic MH',
            english: 'MH Diagnosis',
        },
        {
            domain: 'care_factors',
            category: 'has_ccc_mental_health_record',
            french: 'Suivi par CCC Clinic',
            english: 'Followed by CCC Clinic',
        },
        {
            domain: 'care_factors',
            category: 'has_sim_siv_mental_health_record',
            french: 'Suivi par SIM/SIV',
            english: 'Followed by SIM/SIV',
        },
        {
            domain: 'care_factors',
            category: 'has_clsc_mental_health_record',
            french: 'Connu pour MH',
            english: 'Known to MH',
        },
        {
            domain: 'care_factors',
            category: 'has_retired_income',
            french: 'De RPA',
            english: 'From RPA',
        },
        {
            domain: 'care_factors',
            category: 'is_75_or_more',
            french: '75+',
            english: '75+',
        },
        {
            domain: 'care_factors',
            category: 'is_recurrent_patient',
            french: 'GUS',
            english: 'GUS',
        },
        {
            domain: 'care_factors',
            category: 'has_geriatric_record',
            french: 'Connu pour Geri Clinique',
            english: 'Known to Geri Clinic',
        },
        {
            domain: 'care_factors',
            category: 'has_psycho_geriatric_record',
            french: 'Connu pour PsychcGeri Clinique',
            english: 'Known to PsychGeri Clinic',
        },
        {
            domain: 'care_factors',
            category: 'has_di_tsa_record',
            french: 'Suivi par DI-TSA',
            english: 'Followed by DI-TSA',
        },
        {
            domain: 'care_factors',
            category: 'has_vulnerability_signals',
            french: 'ISO SMAF',
            english: 'ISO SMAF',
        },
        {
            domain: 'care_factors',
            category: 'is_highly_vulnerable',
            french: 'Très Vulnérable',
            english: 'High Vulnerability',
        },
        {
            domain: 'care_factors',
            category: 'is_living_alone',
            french: 'Vit seul',
            english: 'Living Alone',
        },
        {
            domain: 'care_factors',
            category: 'has_home_support',
            french: 'Connu pour SAD',
            english: 'Known to SAD',
        },
        {
            domain: 'care_factors',
            category: 'has_intermediate_income',
            french: 'De RI',
            english: 'From RI',
        },
        {
            domain: 'unit_group_census',
            category: 'CSUR',
            french: 'Cardiologie',
            english: 'Cardiology',
        },
        {
            domain: 'unit_group_census',
            category: 'CHIR',
            french: 'Chirurgie',
            english: 'Surgery',
        },
        {
            domain: 'unit_group_census',
            category: 'FAM',
            french: 'Médecin de Famille',
            english: 'Family Med',
        },
        {
            domain: 'unit_group_census',
            category: 'SPECIAL_UNITS',
            french: 'Unités spéciales',
            english: 'Special Units',
        },
        {
            domain: 'unit_group_census',
            category: 'IM_TEACH',
            french: 'MI Enseignate',
            english: 'IM Teach',
        },
        {
            domain: 'unit_group_census',
            category: 'IM_NON_TEACH',
            french: 'MI non Enseignate',
            english: 'IM non Teach',
        },
        {
            domain: 'unit_group_census',
            category: 'SI',
            french: 'MSICU',
            english: 'MSICU',
        },
        {
            domain: 'unit_group_census',
            category: 'PSY',
            french: 'Psychiatrie',
            english: 'Psychiatry',
        },
        {
            domain: 'unit_group_census',
            category: 'ONC',
            french: 'Oncologie',
            english: 'Oncology',
        },
        {
            domain: 'unit_group_census',
            category: 'PALL',
            french: 'Palliatif',
            english: 'Palliative',
        },
        {
            domain: 'unit_group_census',
            category: 'HAD',
            french: 'HAD',
            english: 'HAD',
        },
        {
            domain: 'unit_group_census',
            category: 'GER',
            french: 'Gériatrie',
            english: 'Geriatrics',
        },
        {
            domain: 'unit_group_census',
            category: 'SSU',
            french: 'SSU',
            english: 'SSU',
        },
        {
            domain: 'unit_group_census',
            category: 'NEUR',
            french: 'Neurologie',
            english: 'Neurology',
        },
    ],
}

export const DISCHARGE_FACTORS_MOCK = {
    categories: [
        {
            categoryId: 1,
            name: 'Clinical care',
            options: [
                {
                    optionId: 1,
                    name: 'Delirium symptoms',
                    isChecked: true,
                },
                {
                    optionId: 2,
                    name: 'OPAT',
                    isChecked: true,
                },
                {
                    optionId: 3,
                    name: 'Surgery required',
                    isChecked: true,
                },
                {
                    optionId: 4,
                    name: 'Pacemaker required',
                    isChecked: true,
                },
                {
                    optionId: 5,
                    name: 'Diet as Tolerated (DAT) required',
                    isChecked: true,
                },
                {
                    optionId: 6,
                    name: 'Trial of void (TOV) required',
                    isChecked: true,
                },
                {
                    optionId: 7,
                    name: 'Blood/iron transfusions required',
                    isChecked: true,
                },
                {
                    optionId: 8,
                    name: 'Cleared by multidisciplinary team',
                    isChecked: true,
                },
            ],
        },
        {
            categoryId: 2,
            name: 'Chemo',
            options: [
                {
                    optionId: 9,
                    name: 'In-patient chemo treatment required',
                    isChecked: true,
                },
                {
                    optionId: 10,
                    name: 'Out-patient chemo appointment required',
                    isChecked: true,
                },
            ],
        },
        {
            categoryId: 3,
            name: 'Community care',
            options: [
                {
                    optionId: 11,
                    name: 'RAD Complexe',
                    isChecked: true,
                },
                {
                    optionId: 12,
                    name: 'DSIE to be submitted',
                    isChecked: true,
                },
                {
                    optionId: 13,
                    name: 'SAD/SAPA required',
                    isChecked: true,
                },
                {
                    optionId: 14,
                    name: 'Mental health services required',
                    isChecked: true,
                },
                {
                    optionId: 15,
                    name: 'DI-TSA services required',
                    isChecked: true,
                },
                {
                    optionId: 16,
                    name: 'Other CLSC services required',
                    isChecked: true,
                },
                {
                    optionId: 17,
                    name: 'CLSC equipment required',
                    isChecked: true,
                },
                {
                    optionId: 18,
                    name: 'Lack of family MD',
                    isChecked: true,
                },
            ],
        },
        {
            categoryId: 4,
            name: 'Legal issues',
            options: [
                {
                    optionId: 19,
                    name: 'Preventive confinement',
                    isChecked: true,
                },
                {
                    optionId: 20,
                    name: 'Temporary confinement judgment',
                    isChecked: true,
                },
                {
                    optionId: 21,
                    name: 'Confinement in an institution judgment',
                    isChecked: true,
                },
                {
                    optionId: 22,
                    name: 'Treatment order',
                    isChecked: true,
                },
                {
                    optionId: 23,
                    name: 'Placement order',
                    isChecked: true,
                },
                {
                    optionId: 24,
                    name: 'Detention without modalities',
                    isChecked: true,
                },
                {
                    optionId: 25,
                    name: 'Detention with modalities',
                    isChecked: true,
                },
                {
                    optionId: 26,
                    name: 'Conditional discharge',
                    isChecked: true,
                },
                {
                    optionId: 27,
                    name: 'Conditional discharge with delegation of authority',
                    isChecked: false,
                },
                {
                    optionId: 28,
                    name: 'Waiting for hearing',
                    isChecked: true,
                },
                {
                    optionId: 29,
                    name: 'Immigration status',
                    isChecked: false,
                },
                {
                    optionId: 30,
                    name: 'Other legal issues',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 5,
            name: 'Immigration & healthcare documentation',
            options: [
                {
                    optionId: 31,
                    name: 'Lack of RAMQ',
                    isChecked: false,
                },
                {
                    optionId: 32,
                    name: 'Expired RAMQ',
                    isChecked: false,
                },
                {
                    optionId: 33,
                    name: 'Irregular immigration status',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 6,
            name: 'Competency & curatorship',
            options: [
                {
                    optionId: 34,
                    name: 'Concerns around competency',
                    isChecked: false,
                },
                {
                    optionId: 35,
                    name: 'Tutorship (curatorship)',
                    isChecked: false,
                },
                {
                    optionId: 36,
                    name: 'Homologated Mandated',
                    isChecked: true,
                },
                {
                    optionId: 37,
                    name: 'Trust',
                    isChecked: false,
                },
                {
                    optionId: 38,
                    name: 'Legal representative',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 7,
            name: 'Imaging and tests',
            options: [
                {
                    optionId: 39,
                    name: 'Blood test required',
                    isChecked: false,
                },
                {
                    optionId: 40,
                    name: 'Cardiac ECHO required',
                    isChecked: false,
                },
                {
                    optionId: 41,
                    name: 'CT scan required',
                    isChecked: false,
                },
                {
                    optionId: 42,
                    name: 'MRI required',
                    isChecked: false,
                },
                {
                    optionId: 43,
                    name: 'Ultrasound required',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 8,
            name: 'Procedures',
            options: [
                {
                    optionId: 44,
                    name: 'PICC line required',
                    isChecked: false,
                },
                {
                    optionId: 45,
                    name: 'PICC line removal required',
                    isChecked: false,
                },
                {
                    optionId: 46,
                    name: 'MIDD line required',
                    isChecked: false,
                },
                {
                    optionId: 47,
                    name: 'Other lines (JP, IVs, drains, etc.) required',
                    isChecked: false,
                },
                {
                    optionId: 48,
                    name: 'Biopsy required',
                    isChecked: false,
                },
                {
                    optionId: 49,
                    name: 'Holter required',
                    isChecked: false,
                },
                {
                    optionId: 50,
                    name: 'Priority scopes (G&C scopes) required',
                    isChecked: false,
                },
                {
                    optionId: 51,
                    name: 'IR draining required',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 9,
            name: 'Patient and caregiver discharge planning',
            options: [
                {
                    optionId: 52,
                    name: 'Caregiver disagreement with discharge plan',
                    isChecked: false,
                },
                {
                    optionId: 53,
                    name: 'Caregiver capacity limitations',
                    isChecked: false,
                },
                {
                    optionId: 54,
                    name: 'Patient refuses to leave',
                    isChecked: false,
                },
                {
                    optionId: 55,
                    name: 'Irregular immigration status',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 10,
            name: 'Social & Housing',
            options: [
                {
                    optionId: 56,
                    name: 'Lives alone',
                    isChecked: false,
                },
                {
                    optionId: 57,
                    name: 'Housing instability',
                    isChecked: false,
                },
                {
                    optionId: 58,
                    name: 'Conflict in living situation',
                    isChecked: false,
                },
                {
                    optionId: 59,
                    name: 'Abuse / Maltraitance',
                    isChecked: false,
                },
                {
                    optionId: 60,
                    name: 'No fixed housing',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 11,
            name: 'Transfer',
            options: [
                {
                    optionId: 61,
                    name: 'Waiting JGH bed',
                    isChecked: false,
                },
                {
                    optionId: 62,
                    name: 'Waiting transfer other institution',
                    isChecked: false,
                },
                {
                    optionId: 63,
                    name: 'Waiting Virtual Ward',
                    isChecked: false,
                },
            ],
        },
        {
            categoryId: 12,
            name: 'Transport',
            options: [
                {
                    optionId: 64,
                    name: 'Ambulance required',
                    isChecked: false,
                },
                {
                    optionId: 65,
                    name: 'Family transport required (outside room unavailable)',
                    isChecked: false,
                },
                {
                    optionId: 66,
                    name: 'Transport adapte required',
                    isChecked: false,
                },
            ],
        },
    ],
    other: 'Some test string about something.',
}
